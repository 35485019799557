import React from 'react';
import { Typography } from '@material-ui/core';
import {
  Content,
  Header,
  InfoCard,
  Page,
} from '@backstage/core-components';
import { Grid, makeStyles } from '@material-ui/core';
import {
  ComponentAccordion,
  HomePageToolkit,
  HomePageCompanyLogo,
  HomePageStarredEntities,
  type Tool,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import useSWR from 'swr';
import LogoFull from '../Root/LogoFull';
import { NewAnnouncementBanner } from '@k-phoen/backstage-plugin-announcements';

export const deepLink1 = {
  link: 'create',
  title: 'Create a new templated product',
};

export const deepLink2 = {
  link: 'explore/tools',
  title: 'Explore our products',
};

export const deepLink3 = {
  link: 'docs',
  title: 'Read our Docs',
};

export const TestComponent = () => (
          <InfoCard title="">
            <Typography variant="body1">
              This portal is designed to improve Developer Experience in our organisation.
            </Typography>
          </InfoCard>
);

export const TestComponent1 = () => (
          <InfoCard title="Create a new component" deepLink={deepLink1}>
            <Typography variant="body1">
              Use pre-defined "Golden Path" templates - according to industry best practices to quickly spin up your own component
            </Typography>
          </InfoCard>
);

export const TestComponent2 = () => (
          <InfoCard title="Explore our products" deepLink={deepLink2}>
            <Typography variant="body1">
              Find out more about how we organize our products and components, and learn more about individuals ones.
            </Typography>
          </InfoCard>
);

export const TestComponent3 = () => (
          <InfoCard title="Documentation" deepLink={deepLink3}>
            <Typography variant="body1">
              Read our Documentations regarding products and get insights how to be successful
            </Typography>
          </InfoCard>
);

type QuickAccessLinks = {
  title: string;
  isExpanded?: boolean;
  links: (Tool & { iconUrl: string })[];
};

const useQuickAccessStyles = makeStyles({
  img: {
    height: '40px',
    width: 'auto',
  },
});

const fetcher = (...args: Parameters<typeof fetch>) =>
  fetch(...args).then(r => r.json()) as Promise<QuickAccessLinks[]>;

const QuickAccess = () => {
  const classes = useQuickAccessStyles();
  const { data, error, isLoading } = useSWR('/homepage/data.json', fetcher);

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;
  if (!data) return <div>data could not be found</div>;

  return (
    <InfoCard title="Explore our Ecosystem" noPadding>
      {data.map(item => (
        <HomePageToolkit
          key={item.title}
          title={item.title}
          tools={item.links.map(link => ({
            ...link,
            icon: (
              <img
                className={classes.img}
                src={link.iconUrl}
                alt={link.label}
              />
            ),
          }))}
          Renderer={
            item.isExpanded
              ? props => <ComponentAccordion expanded {...props} />
              : props => <ComponentAccordion {...props} />
          }
        />
      ))}
    </InfoCard>
  );
};

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 10px',
    borderRadius: '50px',
    margin: 'auto',
  },
  imageIcon: {
    height: '40px',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0, 1, 0),
  },
  svg: {
    width: 'auto',
    height: 80,
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { svg, container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="website">
        <Header title="sX CNP" subtitle="Cloud Native Platform Entry Page" />
        <Content>
        <Grid container>
          <Grid item md={12}>
            <NewAnnouncementBanner />
          </Grid>
        </Grid>
          <Grid container justifyContent="center" spacing={2}>
            <HomePageCompanyLogo
              className={container}
              logo={<LogoFull classes={{ svg }} />}
            />
            <Grid container item xs={12}>
              <Grid item md={12}>
                <TestComponent />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item md={4}>
                <TestComponent1 />
              </Grid>
              <Grid item md={4}>
                <TestComponent2 />
              </Grid>
              <Grid item md={4}>
                <TestComponent3 />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={7}>
                <QuickAccess />
              </Grid>
              <Grid item xs={12} md={5}>
                <HomePageStarredEntities />
              </Grid>
            </Grid>
            <Grid item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                placeholder="search items in sX CNP"
              />
            </Grid>          
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
