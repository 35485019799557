import {
  CatalogKindExploreContent,
  ExploreLayout,
  ToolExplorerContent,
  GroupsExplorerContent,
} from '@backstage/plugin-explore';
import React from 'react';

export const ExplorePage = () => {
  return (
    <ExploreLayout
      title="Explore the sX CNP ecosystem"
      subtitle="Discover solutions available in your ecosystem"
    >


      <ExploreLayout.Route path="tools" title="Tools">
        <ToolExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="groups" title="Groups">
        <GroupsExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="systems" title="Systems">
        <CatalogKindExploreContent kind="system" />
      </ExploreLayout.Route>
{/*       <ExploreLayout.Route path="domains" title="Domains">
        <CatalogKindExploreContent kind="domain" />
      </ExploreLayout.Route> */}
    </ExploreLayout>
  );
};

export const explorePage = <ExplorePage />;
